<template>
<div class='page'>
   <div class="banner">
            <div class="w"> 
                <div class="info">
                    <div class="title">Automotive</div>
                    <!-- <p class="title1">A wide range of plastics for CNC machining, with applications in multiple industries. Ideal for both one-off prototypes and end-use custom parts.</p> -->
                    
                  
                </div>
            </div>
        </div>
 
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 
 <industrieshead :industriesheadLists="industriesheadLists"></industrieshead>
   <applications2 :ApplicationsLists="ApplicationsLists"></applications2>
    <capabilities :CapabilitiesList="CapabilitiesList"></capabilities>
  <customers></customers>
 
</div>
</template>
<script>
import bread from '../../components/bread.vue'
import myhead from "../../components/myHead.vue";
import industrieshead from "../../components/industrieshead.vue";
import applications2 from "../../components/Applications2.vue";
import customers from "../../components/Customers.vue"
import capabilities from "../../components/Capabilities.vue"
export default {
    components: {
    myhead,
    industrieshead,
    applications2,
    customers,
    capabilities,
    bread
  },
  data() {
    return {
           hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
       CapabilitiesList:{
        H1:'AUTOMOTIVE POST-PROCESSING CAPABILITIES',
        lists:[{
            title:'Anodizing',
        },{
            title:'Sandblasting',
        },{
            title:'Electroplating',
        },{
            title:'Painting',
        },{
            title:'Insert Installation',
        },{
            title:'Heat Treatment',
        },],
        img: require('../../assets/img/automotiveca1.png')
    }, 
      industriesheadLists: {
        ref: "Automotive",
        H1: "AUTOMOTIVE",
        info: `CNC machining technology has been applied to all aspects of automobile research and development (concept design, model car, prototype, ...), the supply of auto parts (rearview mirrors, instrument panels, air conditioning components, lamp components, ...) and Fixtures, production jigs, etc. in the automotive production process. It can help automotive engineers and designers to conduct a comprehensive design evaluation before components are put into production, and also help companies greatly reduce R&D costs and shorten product launch time.`,
        imgs: [
          {
            src: require("../../assets/img/Automotive1.png")
          }
        ],
        h2: "Top Precision Automotive Applications",
        data: [
          {
       
            content: `<span style="font-weight: bold"> Interior Components:</span> Car manufacturers can product prototypes tailored to augment the interior of a vehicle.`
          },
          {
       
            content:  `<span style="font-weight: bold">Exterior Components: </span>Exterior prototypes enhance the exterior aspect of a car.`
          },
          {
      
            content: `<span style="font-weight: bold">Functional Components: </span>These are prototypes that house the electro-mechanics of a vehicle.`
          }
        ]
      },
      ApplicationsLists: {
        H1: "WHY HLH fOR AUTOMOTIVE DEVELOPMENT ？",
        data: [
          {
            img: require("../../assets/img/aautomotive1model1.png"),
            title: "Rapid Prototyping",
            info:"Mitigate design risk through rapid iteration and prototyping in production materials without sacrificing development speed."
          },
          {
            img: require("../../assets/img/aautomotive1model2.png"),
            title: "Quality Inspections",
            info:
              "Validate part geometry with several quality documentation options. Digital inspection, PPAP, and FAI reporting are available."
          },
          {
            img: require("../../assets/img/aautomotive1model3.png"),
            title: "Tooling and Fixtures",
            info: "Improve manufacturing processes to create greater automation and streamlined component assembly with custom fixturing."
          }
        ]
      }
    };
  },
  
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},

};
</script>
<style scoped lang="scss">
.page {
}

  .banner{
        background: url('~@/assets/img/automotivebanner.png') no-repeat;
        
  
        width: 100%;
        // height: 560px;
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
            display: flex;
            justify-content: flex-end;
            margin-top: 87px;
        }
    
        
        .title1{
            // font-size: 60px;
            margin-bottom: 2vw;
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
            // margin-top: 30px;    
            .item{
                display: flex;
                align-items: center;
            
            }
            >p {
                // margin-bottom: 30px;
                img {
                    vertical-align: middle;
                    margin-right: 20px;
                    // width: 36px;
                    // height: 36px;
                    max-width: 36px;
                    min-width: 25px;
                 
                }
                span {
                    vertical-align: middle;
                }
            }
            >.title {
                // font-size: 48px;
                // margin-bottom: 0;
                color: #333333;
                font-weight: bold;
           transition: 0.25s;
            }
        }
    }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
    .info{
      position: absolute;
   right: 17vw;
    top: 19vw;
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
        font-size: 32px;
        font-weight: bold;
          margin-bottom: 6vw;
    } 
    .title1{
           font-size: 15px;
        font-weight: bold;
        width: 62vw;
          // line-height: 25px;
    }
    img{
        width: 26px
    }
    .itemT{
        font-size: 14px;
    }
    p{
        margin-bottom:10px ;
    }
    .banner{
        height: 500px;
          background-position: 59% 100% !important;
    }
     .btn{
        font-size: 14px;
        margin-left: 48px;
    }
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {

    .w{
        width: 90%;
    }
    .title{
        font-size: 35px;
        margin-bottom: 54px;
    }
    .title1{
            font-size: 22px;
              line-height: 36px;
          width:50vw;
    }
    img{
        width: 30px;
    }
    .itemT{
        font-size: 22px;
    }
    p{
        margin-bottom:30px ;
    }
    .banner{
        height: 620px;
        background-position: center !important;
    }
    .btn{
        margin-left: 64px;
        font-size: 16px;
    }
    .info{
        margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 54px;
    }
   .title1{
        font-size: 22px;
        width:45vw;
          line-height: 36px;
    }
    img{
        width: 36px;}
        .itemT{
            font-size: 24px;
        }  p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
    .w{
        width: 79%;
    }
    .title{
        font-size: 50px;
        margin-bottom: 54px;
    }
    .title1{
           font-size: 24px;
        width: 650px;
          line-height: 36px;
    }
    .itemT{
        font-size: 24px;
    }
    img{
        width: 36px;}
        p{
            margin-bottom:30px ;
        }
        .banner{
            height: 620px;
            background-position: center !important;
        }
        .btn{
            margin-left: 64px;
            font-size: 16px;
        }
        .info{
            margin-top: 30px;
        }
}
</style>